import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FeatureArticle from '../components/cards/featureArticle';
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const query = graphql`
    query FeatureArticles {
      featureArticles: allMdx(sort: {order: DESC, fields: frontmatter___date}, limit: 100) {
        edges {
          node {
            frontmatter {
              category
              date(formatString: "MMMM Do, YYYY")
              description
              heroImage {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              title
              tags
            }
            slug
          }
        }
      }
    }
  `;

  const data = useStaticQuery(query);
  const articles = data.featureArticles.edges;
  
  return (
    <Layout>
      <Seo title="Home" />
      {articles.map((article) => {
        const { slug, frontmatter } = article.node;
        return (
          <FeatureArticle slug={slug} key={slug} frontmatter={frontmatter} />
        );
      })}
    </Layout>
  )
};

export default IndexPage
